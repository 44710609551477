
// mq values (no unit)
$bp--small-value: 28;
$bp--medium-value: 45;
$bp--large-value: 60;
$bp--xlarge-value: 75;
$bp--xxlarge-value: 90;
$bp--xxxlarge-value: 110;

@supports (font-variation-settings: normal) {
  :root {

    /* Breakpoint variables */
    --bp-small: #{$bp--small-value};
    --bp-medium: #{$bp--medium-value};
    --bp-large: #{$bp--large-value};
    --bp-xlarge: #{$bp--xlarge-value};
    --bp-xxlarge: #{$bp--xxlarge-value};
    --bp-xxxlarge: #{$bp--xxxlarge-value};
  
    /* General text variables */
    --text-wght: 400;
    --text-size-base: 16;
    
    /* Paragraph variables */
    --p-line-height-min: 1.4;
    --p-line-height-max: 1.5;
    --p-font-size-min: 1;
    --p-font-size-max: 1.125;
    --p-vf-wght: 350;
    --p-vf-opsz: 16;
    --p-vf-GRAD: 0;
    --p-vf-slnt: 0;
  
    /* H1 variables */
    --h1-line-height-min: 1;
    --h1-line-height-max: 1.05;
    --h1-font-size-min: 2.5;
    --h1-font-size-max: 4.5;
    --h1-vf-wght-multiplier-s: 0.9;
    --h1-vf-wght-multiplier-m: 0.85;
    --h1-vf-wght-multiplier-l: 0.8;
    --h1-vf-wdth: 75;
  
    /* H2 variables */
    --h2-line-height-min: 1;
    --h2-line-height-max: 1.05;
    --h2-font-size-min: 2.125;
    --h2-font-size-max: 3;
    --h2-vf-wght-multiplier-s: 0.7;
    --h2-vf-wght-multiplier-m: 0.8;
    --h2-vf-wght-multiplier-l: 0.9;
    --h2-vf-wdth: 50;

    /* H3 variables */
    --h3-line-height-min: 1.05;
    --h3-line-height-max: 1.1;
    --h3-font-size-min: 1.5;
    --h3-font-size-max: 2.5;
    --h3-vf-wght-multiplier-s: 0.7;
    --h3-vf-wght-multiplier-m: 0.8;
    --h3-vf-wght-multiplier-l: 0.9;
    --h3-vf-wdth: 40;

    /* H4 variables */
    --h4-line-height-min: 1.1;
    --h4-line-height-max: 1.2;
    --h4-font-size-min: 1.25;
    --h4-font-size-max: 2.125;
    --h4-vf-wght-multiplier-s: 0.7;
    --h4-vf-wght-multiplier-m: 0.7;
    --h4-vf-wght-multiplier-l: 0.7;
  
    /* H5 variables */
    --h5-line-height-min: 1.1;
    --h5-line-height-max: 1.15;
    --h5-font-size-min: 1.125;
    --h5-font-size-max: 1.25;
    --h5-vf-wght-multiplier-s: 0.7;
    --h5-vf-wght-multiplier-m: 0.8;
    --h5-vf-wght-multiplier-l: 0.9;
  }
}
