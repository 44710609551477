/* Material styles */
.mdc-drawer-app-content {
  flex: auto;
  overflow: auto;
  position: relative;
}

.main-content {
  height: 100%;
}

/* Site styles */
.vf-content-wrapper {
  width: 100%;
}

.vf-main-content {
}

