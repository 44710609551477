// Colors
// =============================================================================

$color-blue: #0662ed;

$color-icon-book: #3068F6;
$color-icon-website: #CD00EA;
$color-icon-article: #008B00;
$color-icon-video: #E54304;

// Google colors
$color-gray-900: #212121;
$color-gray-800: #424242;
$color-gray-700: #616161;
$color-gray-600: #757575;
$color-gray-500: #9E9E9E;
$color-gray-400: #BDBDBD;
$color-gray-300: #E0E0E0;
$color-gray-200: #EEEEEE;
$color-gray-100: #F5F5F5;
$color-gray-50:  #FAFAFA;

$color-blue-900: #2838c4;
$color-blue-800: #295ce4;
$color-blue-700: #296ff6;
$color-blue-600: #3481f7;
$color-blue-500: #3b8ff7;
$color-blue-400: #45a0f8;
$color-blue-300: #66b1f9;
$color-blue-200: #95c7fa;
$color-blue-100: #bfddfc;
$color-blue-50: #e4f2fe;

$color-purple-900: #4f14c4;
$color-gray-750: #4f5358;

$color-black: #000;
$color-white: #FFF;

/* Demo colors */

$color-lavender-light: #f6e8ff;
$color-yellow-light: #ecec96;
$color-green-light: #caf8ea;
$color-peach-light: #fff6e9;
$color-peach: #eedbbf;
$color-silver: #E5E5E5;

$color-callout-wght: #ffccbc;
$color-callout-wdth: #2196f3;
$color-callout-ital: #ff8a65;
$color-callout-slnt: #c8e6c9;
$color-callout-opsz: #e1bee7;
$color-callout-casl: #b2dfdb;


/* functional assignments */

$color-heading: $color-gray-900;
$color-text: $color-gray-800;
$color-text-highlight: rgba($color-gray-300, 0.75);
$color-text-highlight-secondary: $color-gray-50;
$color-card-border: $color-gray-200;
$color-callout-highlight: $color-blue-50;
$color-callout-highlight-secondary: lighten($color-blue-50, 2.5%);
$color-text-link: $color-gray-900;
$color-text-link-visited: $color-gray-900;
$color-text-link-hover: $color-gray-800;
$color-text-link-focus: $color-gray-800;
$color-text-link-active: $color-gray-800;
$color-text-link-active-trail: $color-gray-900;
$color-slider-group-background: $color-gray-100;

$header-background-gray-light: $color-gray-200;
$header-background-gray-lighter: $color-gray-100;
$header-background-blue-lighter: $color-blue-50;