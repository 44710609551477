@import "@material/top-app-bar/mdc-top-app-bar";
@import "@material/icon-button/mdc-icon-button";

.app-bar {
  position: absolute;
}

.mdc-top-app-bar {
  --mdc-theme-primary: rgba(255, 255, 255, 0);
  --mdc-theme-on-primary: var(--color-text);
  transition: background-color 0.3s ease-in-out;
  background-color: rgba(255, 255, 255, 1.0);
  background-color: var(--color-background);
  border-bottom: 1px solid rgba(0, 0, 0, 0.14);
  color: rgba(0, 0, 0, 0.54);
  @media (prefers-color-scheme:dark) {
    border-color: rgba(255, 255, 255, 0.14);
  }
  // &__row {
  //   // This was already here, matches the margin
  //   // in drawer, but might not be necessary?
  //   height: 4rem;
  // }
  // &__section {
  //   padding: 0.5rem 0.75rem;
  // }
  &__navigation-icon.material-icons {
    @media (min-width: $bp--large) {
      display: none;
      visibility: hidden;
    }
  }
  &__title {
    font-family: $sans-serif-novf;
    padding-left: 1rem;
    @supports (font-variation-settings: normal) {
      font-family: $sans-serif;
      font-weight: 725;
      font-stretch: 85%;
    }
  }
  a {
    color: darken($color-gray-700 ,10%);
    @media (prefers-color-scheme:dark) {
      color: var(--color-text);
    }
  &:link,
    &:visited {
      text-decoration: none;
    }
    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }

  }
}

.top-app-bar-tabs {
  display: none;
  visibility: hidden;
  @media (min-width: $bp--large) {
    display: flex;
    align-items: stretch;
    visibility: visible;
  }
}

.top-app-bar-tabs-item,
a.top-app-bar-tabs-item {
  height: 100%;
  font-weight: 400;

  /* TODO These should use MDC variables */
  padding: 8px 12px;
}

.top-app-bar-tabs-item--activated,
a.top-app-bar-tabs-item--activated {
  font-weight: var(--link-font-weight);
  color: var(--color-text, #{$color-gray-800});
}
