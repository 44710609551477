
.md-slider-group {
  &__slider-unit {
    align-items: center;
    display: grid;
    grid-template-columns: max-content 1fr max-content;
  }
  [class*="value-"] {
    font-family: $sans-serif-novf;
    @supports (font-variation-settings: normal) {
      font-family: $sans-serif;
      font-stretch: 50%;
    }
  }
  .value-tag {
    font-weight: var(--link-font-weight);
  }
  .value-low {
    padding-right: 0.5rem;
  }
  .value-high {
    padding-left: 0.5rem;
  }
  .value-current {
    background: transparent;
    border: solid 1px $color-gray-600;
    display: inline-block;
    font-size: 1rem;
    grid-column: 2/3;
    justify-self: center;
    width: 4rem;
    margin: 0 auto;
    padding: 0.25rem 0.25rem 0.2rem 0.25rem;
    text-align: center;
    /* Using the 'Discrete Slider' so don't need this to display but still need its value */
    display: none;
  }
  &.wide {
    display: grid;
    grid-template-columns: 1fr;
    @media (min-width: $bp--medium) {
      grid-template-columns: max-content 1fr;
    }
    .value-tag {
      line-height: 2;
      padding-right: 0.5rem;
      @media (min-width: $bp--medium) {
        line-height: 3;
      }
      &::after {
        content: ":";
      }
    }
  }
}
