// Links
// =============================================================================

:root {
  --link-font-weight: 525;
}

a {
  text-decoration-skip-ink: auto;
  &:link {
    color: $color-text-link;
    color: var(--color-text-link);
    font-weight: var(--link-font-weight, 525);
    text-decoration-line: underline;
    text-decoration-skip-ink: auto;
    text-decoration-thickness: 0.05em;
    text-underline-offset: .075em;
    transition: all .2s ease; 
  }
  &:visited,
  &.visited {
    color: $color-text-link-visited;
    color: var(--color-text-link-visited);
    font-weight: var(--link-font-weight, 525);
    text-decoration-line: underline;
    text-decoration-skip-ink: auto;
    text-decoration-style: dotted;
    text-decoration-color: $color-gray-500;
}
  &:hover {
    color: $color-text-link-hover;
    color: var(--color-text-link-hover);
    text-decoration-line: underline;
    text-decoration-skip-ink: auto;
    text-decoration-thickness: 0.1em;
    text-decoration-color: var(--color-text-link-hover);
  }
  &:focus {
    color: $color-text-link-focus;
    color: var(--color-text-link-focus);
    text-decoration-line: underline;
    text-decoration-skip-ink: auto;
    text-decoration-thickness: 0.1em;
  }
  &:active {
    color: $color-text-link-active;
    color: var(--color-text-link-active);
    text-decoration-line: underline;
    text-decoration-skip-ink: auto;
  }
}
