@import "../../00-global/_02-colors.scss";
@import "../../01-atoms/02-forms/slider/_slider.scss";

@font-face {
  font-family: "Jost";
  src: url("../../../../assets/fonts/embeds/jost-VF-subset.woff2")
    format("woff2 supports variations"), url("../../../../assets/fonts/embeds/jost-VF-subset.woff2")
    format("woff2-variations");
  font-weight: 100 900;
  unicode-range: U+0020-007F, U+00A0-00FF, U+0100-017F;
}

@font-face {
  font-family: "Literata";
  src: url("../../../../assets/fonts/embeds/literata-VF-regular.woff2")
    format("woff2 supports variations"), url("../../../../assets/fonts/embeds/literata-VF-regular.woff2")
    format("woff2-variations");
  font-weight: 400 700;
  font-style: normal;
}

@font-face {
  font-family: "Literata";
  src: url("../../../../assets/fonts/embeds/literata-VF-italic.woff2")
    format("woff2 supports variations"), url("../../../../assets/fonts/embeds/literata-VF-italic.woff2")
    format("woff2-variations");
  font-weight: 400 700;
  font-style: italic;
}

@font-face {
  font-family: "Inter VF";
  src: url("../../../../assets/fonts/embeds/Inter-V-subset.woff2")
    format("woff2 supports variations"), url("../../../../assets/fonts/embeds/Inter-V-subset.woff2")
    format("woff2-variations");
  font-display: swap;
  font-weight: 100 900;
  font-style: oblique 0deg 10deg
}
@font-face {
  font-family: "Amstelvar VF";
  src: url("../../../../assets/fonts/embeds/Amstelvar-Roman-VF.woff2")
    format("woff2 supports variations"), url("../../../../assets/fonts/embeds/Amstelvar-Roman-VF.woff2")
    format("woff2-variations");
  font-display: swap;
  font-weight: 100 900;
  unicode-range: U+0020-007F, U+00A0-00FF, U+0100-017F;
}

body {
  margin: 0;
  padding: 0;
}

.range__axis {
  --value-tag: 'wght';
  --low-value: 85;
  --current-value: var(--text-vf-wght);
  --high-value: 100;
  background-color: var(--color-callout-highlight);
  border: solid 1px var(--color-text-callout);
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-left: auto;
  margin-right: auto;
  .paragraph {
    border-top: solid 1px var(--color-text-callout);
    display: flex;
    justify-content: center;
    min-height: 15rem;

    .text {
      align-self: center;
      font-family: "Roboto Extremo", Helvetica, sans-serif;
      font-size: 5rem;
      font-weight: var(--text-vf-wght);
      font-stretch: calc( var(--text-vf-wdth) * 1% );
      line-height: 7rem;
      margin: 0 auto 0 auto;
    }
  }
  &__label {
    background: 
    linear-gradient(to bottom, 
      rgba(0,0,0,0), 
      rgba(0,0,0,0) 0.575rem, 
      var(--color-text-callout) 0.6rem, 
      var(--color-text-callout) 0.6rem, 
      rgba(0,0,0,0) 0.625rem,
      rgba(0,0,0,0) 0.9rem, 
      var(--color-text-callout) 0.925rem, 
      var(--color-text-callout) 0.925rem, 
      rgba(0,0,0,0) 0.95rem,
      rgba(0,0,0,0) 1.2rem, 
      var(--color-text-callout) 1.225rem, 
      var(--color-text-callout) 1.225rem, 
      rgba(0,0,0,0) 1.25rem);
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    padding-bottom: 0;
    text-align: center;
  
    p {
      background-color: var(--color-callout-highlight);
      display: inline-block;
      padding: 0.15rem 1rem 0.05rem;
      text-align: center;
    }
  }
  .md-slider-group {
    border-top: 1px solid;
    background-color: $color-slider-group-background;
    background-color: var(--color-slider-group-background);
    padding: 1rem;
    width: 100%;
    & + .md-slider-group {
      border-top: none;
    }
  }

  .controls {
    display: grid;
    grid-template-columns: max-content auto;
    margin: 0;
    text-align: center;
    padding: 1.5rem 1rem;
    label {
      float: left;
      height: 1px;
      text-indent: -999em;
      visibility: hidden;
    }
  }
}
.range__axis .code {
  background-color: var(--color-callout-highlight-alt);
}

.range__axis code {
  display: block;
  font-size: 1.25rem;
  line-height: 1.5;
  margin: 0 auto;
}

.range__axis code .current-value,
.range__axis code .current-value2 {
  color: #eee;
  color: var(--color-prettify-pun);
}

.range__axis code .current-value::before {
  color: #eee;
  color: var(--color-prettify-pun);
  counter-reset: tag var(--current-value);
  content: counter(tag);
  display: inline;
}

.range__axis code .current-value2::before {
  color: #eee;
  color: var(--color-prettify-pun);
  counter-reset: tag var(--current-value2);
  content: counter(tag);
  display: inline;
}

.code code.prettyprint {
  --color-text-highlight: transparent;
  border: none !important;
  font-size: 1.125rem;
  padding: 1.5rem !important;
}
.code.prettyprint.css {
  position: relative;
}
  
.range__axis.range__axis--width {
  --color-callout-highlight: #{$color-lavender-light};
}
  
.range__axis.range__axis--italic {
  --color-callout-highlight: #{$color-yellow-light};
}
  
.range__axis.range__axis--slant {
  --color-callout-highlight: #{$color-green-light};
}
  
.range__axis.range__axis--opsz-auto {
  --color-callout-highlight: #{$color-blue-100};
}
  
.range__axis.range__axis--opsz {
  --color-callout-highlight: #{$color-lavender-light};
}
  
.range__axis.range__axis--grade {
  --color-callout-highlight: #{$color-yellow-light};
}
  
.range__axis.range__axis--casual {
  --color-callout-highlight: #{$color-green-light};
}
  
.range__axis.range__axis--ascender-descender {
  --color-callout-highlight: #{$color-lavender-light};
}
