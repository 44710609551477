@import "@material/card/mdc-card";

.mdc-card {
  --mdc-theme-surface: var(--color-background);

  

  &__primary-action {
    height: 100%;
    &::before,
    &::after {
      background-color: $color-blue-900;
    }
  }
  &__action--icon:not(:disabled) {
    color: var(--color-text);
  }
  &--outlined {
    border-color: $color-card-border;
    border-color: var(--color-card-border);
  }
  .mdc-icon-button {
    &::before,
    &::after {
      background-color: $color-blue-900;
    }
  }
  a {
    display: block;
    height: 100%;
    &:link,
    &:visited {
      color: var(--color-text);
      text-decoration: none;
    }
    &:hover,
    &:focus,
    &:active {
      color: var(--color-text);
      text-decoration: none;
    }
  }
}


a.vf-mdc-card {
  text-decoration: none;
}

.vf-mdc-card {
  width: 100%;
  border-radius: 2px;
  overflow: hidden;
  border-color: transparent;
  display: block;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transition: box-shadow 0.2s ease-in-out !important;
  &:link,
  &:visited {
    color: var(--color-text);
  }
  &:hover,
  &:focus,
  &:active {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2),
                0px 3px 4px rgba(0, 0, 0, 0.12),
                0px 3px 3px rgba(0, 0, 0, 0.14);
    color: var(--color-text-link);
  }
  


  // These need to be nested for specificity
  .vf-mdc-card__title {
    color: var(--color-text-link);
    margin-top: calc( var(--body-text-spacer) * 1 );
    margin-bottom: calc( var(--body-text-spacer) * 0.2 );
  }

  .vf-mdc-card__subtitle {
    margin-top: 0;
  }

  &.vf-mdc-card--flat {
    box-shadow: none;
  }
}

.vf-mdc-card__description {
  margin-top: calc( var(--body-text-spacer) * 1 );
}

.vf-mdc-card__body {
  padding: calc( var(--body-text-spacer) * 1 );
  padding-bottom: calc( var(--body-text-spacer) * 1.2 );
}

.vf-card-icon {
  margin-top: 0.25rem;
  border-radius: 100%;
  width: 48px;
  height: 48px;
  border-radius: 100%;
}


.vf-card-icon--book,
.vf-card-icon--book:not(:disabled) {
  background-color: rgba($color-icon-book, 0.12);
  color: $color-icon-book;
}

.vf-card-icon--video,
.vf-card-icon--video:not(:disabled) {
  background-color: rgba($color-icon-video, 0.12);
  color: $color-icon-video;
}

.vf-card-icon--website,
.vf-card-icon--website:not(:disabled) {
  background-color: rgba($color-icon-website, 0.12);
  color: $color-icon-website;
}

.vf-card-icon--article,
.vf-card-icon--article:not(:disabled) {
  background-color: rgba($color-icon-article, 0.12);
  color: $color-icon-article;
}
