.home-grid {

  @media (min-width: $bp--medium) {
    display: grid;
    grid-template-columns: minmax(15em, 1fr) minmax(15em, 1fr);
  }

  &__title {
    --h2-vf-wdth: 25;
    --h2-font-size-max: 3.75;
    --h2-vf-wght-multiplier-s: 0.7;
    --h2-vf-wght-multiplier-m: 0.75;
    --h2-vf-wght-multiplier-l: 0.8;
      align-self: baseline;
    @media (min-width: $bp--medium) {
      padding-right: 2rem;
    }
      h2 {
      color: var(--color-text-callout);
      margin-top: 0;

    }
  }

  &__intro {
  }

  &__cards {
    outline: 2px solid red;
    padding-top: calc( var(--body-text-spacer) * 5 );
  }

}
