.footer {
  margin-top: 4rem;
  margin-bottom: 1rem;
  p {
    --p-font-size-min: 0.875;
    --p-font-size-max: 0.925;
    max-width: none;
  }
}

.footer-col {
  margin-bottom: 3rem;
}

/* We use 1024px here to match when the Friends of Google Fonts
 * badge layout changes. */
@media screen and (min-width: 1024px) {
  .footer {
    display: flex;
    flex-direction: row-reverse;
    margin-left: -1rem; 
    margin-right: -1rem;
    margin-bottom: 2rem;
    div {
      width: 100%;
    }
  }
  
  .footer-col {
    padding: 1rem;
    margin-bottom: 0;
  }
  
  .footer-col--badge {
    max-width: 250px;
  }
}

/* The badge has a fairly specific design that switches between
   dark and light depending on the screen size. We aren’t using it
   that way—these overrides change that. */
.GoogleFontsBadge-inner {
  background: transparent !important;
  @media (max-width: $bp--medium) {
    a {
      padding-left: 0 !important;
    }    
  }
  svg {
    fill: var(--color-text) !important;
  }
}

.site-by-grouping {
  align-items: center;
  display: grid;
  grid-template-columns: 5rem 1fr;
  margin-top: 2rem;
  > a:first-child {
    margin-right: 1rem;
  }
  .bizlet-logo {
    background-color: #ffffff;
  }
}

.site-powered {
  margin-top: 2rem;
}
