
.pill-buttons {
  display: flex;
  flex-flow: row wrap;
  list-style: none;
  margin: calc( var(--body-text-spacer) * 1 )  0;
  padding: 0;
  > * {
    flex-basis: 40%;
    flex-grow: 1;
    padding: 1rem 0.5rem 0 0.5rem;
  }
  a {
    &:link,
    &:visited {
      background-color: rgba($color-peach, .5);
      border-radius: 2rem;
      color: $color-gray-900;
      display: block;
      min-width: 9rem;
      text-decoration: none;
      padding: 0.25rem 1rem 0.2rem;
      transition: background-color 0.2s ease-in;
    }
    &:hover {
      background-color: rgba($color-peach, 1);
      color: $color-text-link-hover;
      text-decoration: none;
    }
    .material-icons {
      width: 1.125rem;
      height: 1.125rem;
      &.right {
        float: right;
      }
    }
  }

}