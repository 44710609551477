/* Static web fonts */

/* roboto-regular - latin_latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/roboto/roboto-v20-latin_latin-ext-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto'), local('Roboto-Regular'),
       url('../fonts/roboto/roboto-v20-latin_latin-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto/roboto-v20-latin_latin-ext-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto/roboto-v20-latin_latin-ext-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto/roboto-v20-latin_latin-ext-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto/roboto-v20-latin_latin-ext-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-italic - latin_latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: normal;
  src: url('../fonts/roboto/roboto-v20-latin_latin-ext-italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Italic'), local('Roboto-Italic'),
       url('../fonts/roboto/roboto-v20-latin_latin-ext-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto/roboto-v20-latin_latin-ext-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto/roboto-v20-latin_latin-ext-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto/roboto-v20-latin_latin-ext-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto/roboto-v20-latin_latin-ext-italic.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-700italic - latin_latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: bold;
  src: url('../fonts/roboto/roboto-v20-latin_latin-ext-700italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'),
       url('../fonts/roboto/roboto-v20-latin_latin-ext-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto/roboto-v20-latin_latin-ext-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto/roboto-v20-latin_latin-ext-700italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto/roboto-v20-latin_latin-ext-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto/roboto-v20-latin_latin-ext-700italic.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-700 - latin_latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/roboto/roboto-v20-latin_latin-ext-700.eot'); /* IE9 Compat Modes */
  src: local('Roboto Bold'), local('Roboto-Bold'),
       url('../fonts/roboto/roboto-v20-latin_latin-ext-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto/roboto-v20-latin_latin-ext-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto/roboto-v20-latin_latin-ext-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto/roboto-v20-latin_latin-ext-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto/roboto-v20-latin_latin-ext-700.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-slab-regular - latin_latin-ext */
@font-face {
  font-family: 'Roboto Slab Static';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/roboto-slab/roboto-slab-v10-latin_latin-ext-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/roboto-slab/roboto-slab-v10-latin_latin-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-slab/roboto-slab-v10-latin_latin-ext-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-slab/roboto-slab-v10-latin_latin-ext-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-slab/roboto-slab-v10-latin_latin-ext-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-slab/roboto-slab-v10-latin_latin-ext-regular.svg#RobotoSlab') format('svg'); /* Legacy iOS */
}

/* roboto-slab-700 - latin_latin-ext */
@font-face {
  font-family: 'Roboto Slab Static';
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/roboto-slab/roboto-slab-v10-latin_latin-ext-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/roboto-slab/roboto-slab-v10-latin_latin-ext-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-slab/roboto-slab-v10-latin_latin-ext-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-slab/roboto-slab-v10-latin_latin-ext-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-slab/roboto-slab-v10-latin_latin-ext-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-slab/roboto-slab-v10-latin_latin-ext-700.svg#RobotoSlab') format('svg'); /* Legacy iOS */
}


/* Variable fonts */
/* Load Roboto Slab from GF API */
//@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap');

/* Load Roboto Extremo from GF API */
//@import url('https://fonts.sandbox.google.com/css2?family=Roboto+Extremo:opsz,wdth,wght@8..144,75..125,100..900');

/* Load Recursive from GF API */
//@import url('https://fonts.sandbox.google.com/css2?family=Recursive:ital,slnt,wght,CASL,MONO@0..1,-15..0,300..1000,0..1,0..1');

@font-face{
  font-family: 'Recursive';
  font-weight: 300 1000; /* default: 400 */
  font-style: oblique 0deg 15deg;
  src: url('../fonts/Recursive_VF_1.043--subset_range_english_basic.woff2') format('woff2 supports variations'), url('../fonts/Recursive_VF_1.043--subset_range_english_basic.woff2') format('woff2-variations');
}

@font-face{
  font-family: 'Roboto Extremo';
  font-weight: 100 900; /* default: 400 */
  font-style: oblique 0deg 10deg;
  font-stretch: 25% 150%; /* default: 100% */
  src: url('../fonts/RobotoExtremo-VF-slnt-alpha.woff2') format('woff2 supports variations'), url('../fonts/RobotoExtremo-VF-slnt-alpha.woff2') format('woff2-variations');
  /* opsz: 8-144, 12 default */
  /* GRAD: -1 - 1, 0 default */
  font-display: swap;
}

/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/Material-Icons-v50.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
}
