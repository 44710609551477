.hero--playing .hero-animation-text {
  animation-play-state: running;
}

.hero--paused .hero-animation-text {
  animation-play-state: paused;  
}

.hero-animation-text > span {
  animation: breath 1.5s alternate ease-in-out;
  animation-iteration-count: 500;

  font-family: "Recursive", Helvetica, sans-serif;
  // font-weight: var(--text-vf-wght);
  font-feature-settings: 'ss19' 1;
  max-width: none;
  text-align: center;
}

.hero-animation__controls,
.play-pause {
  display: none;
  visibility: hidden;
}

.hero-animation {
  --h1-font-size-min: 3;
  --h1-font-size-max: 6.5;

  //
  //   &__controls {
  //     display: grid;
  //     grid-template-columns: 1fr;
  //     justify-items: center;
  //     margin: 1rem auto;
  //     @media (min-width: $bp--medium) {
  //       grid-template-columns: max-content 1fr;
  //       max-width: 80vw;
  //   }
  //     > * {
  //       margin: 0 1rem;
  //     }
  //   }
  //   .controls {
  //     opacity: 0;
  //     transition: opacity 0.3s ease-in-out;
  //     display: grid;
  //     grid-template-columns: 1fr 1fr;
  //     margin: 0.5rem 0 0 0;
  //     width: 100%;
  //
  //     @media (min-width: $bp--medium) {
  //       grid-template-columns: 1fr 1fr;
  //       margin-top: 0;
  //     }
  //     > * {
  //       margin: 0 1rem;
  //     }
  //   }
  //   .pause + .controls {
  //     opacity: 1;
  //   }
  //
  // }
  //
  // .play-pause {
  //   --text-wght: 450;
  //   --mdc-theme-primary: #{$color-blue-900};
  //   margin-top: 0.25rem;
  //   text-transform: none;
  //   min-width: 10rem;
  //   &:hover {
  //
  //   }
  //   &.pause::after {
  //     content: "Play";
  //     padding-left: 0.25em;
  //   }
  //   &.play::after {
  //     content: "Pause";
  //     padding-left: 0.25em;
  //   }
  //   &::after {
  //     .play & {
  //       content: "Pause";
  //       padding-left: 0.25em;
  //     }
  //     .pause & {
  //       content: "Play";
  //       padding-left: 0.25em;
  //     }
  //   }
}
