:root {
  --card-grid-gutter: 0.333rem;
}

.card-grid.card-grid--singlerow {
  display: flex;
  flex-wrap: wrap;
  @media (min-width: $bp--medium) {
    flex-wrap: nowrap;
  }
  .card-grid-cell {
    width: 100%;
  }
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(23rem, 1fr));
  grid-auto-rows: 1fr;
  overflow: visible;
  margin-left: calc( -1 * var(--card-grid-gutter) );
  margin-right: calc( -1 * var(--card-grid-gutter) );
  margin-top: calc( var(--body-text-spacer) * 2.4 );
  margin-bottom: calc( var(--body-text-spacer) * 2 );

  @media (min-width: $bp--medium) {
    margin-left: calc( (var(--body-text-spacer) * -1) - var(--card-grid-gutter) );
    margin-right: calc( (var(--body-text-spacer) * -1) - var(--card-grid-gutter) );
  }
  
  > * {
    --h3-font-size-min: 1.25;
    --h3-font-size-max: 1.5;
    --h3-line-height-min: 1.25;
    --h3-line-height-max: 1.35;
    --h4-font-size-min: 0.875;
    --h4-font-size-max: 0.925;
    --h4-vf-wght-multiplier-s: 1;
    --h4-vf-wght-multiplier-m: 1.05;
    --h4-vf-wght-multiplier-l: 1.1;
    --p-font-size-min: 0.925;
    --p-font-size-max: 1;
    --p-line-height-min: 1.35;
    --p-line-height-max: 1.5;
  //   border-top-width: 0.33rem;
  //   margin: 0 1rem 2rem 1rem;
  //   h4 {
  //     font-variation-settings: 'slnt' -10;
  //   }
  }
}

.card-grid-cell {
  padding: var(--card-grid-gutter);
  display: flex;
  align-items: stretch;
}
