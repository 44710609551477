.content-section {
  clear: both;
  padding-left: 1vw;
  padding-right: 1vw;
  @include section-width();

  h2 {
    margin-top: calc( var(--body-text-spacer) * 4 );
    margin-bottom: calc( var(--body-text-spacer) * 2 );
  }
  
  .subtitle,
  h3 {
    margin-top: calc( var(--body-text-spacer) * 2.4 );
    margin-bottom: calc( var(--body-text-spacer) * 1.2 );
  }
  
  h4 {
    margin-top: calc( var(--body-text-spacer) * 1.2 );
    margin-bottom: calc( var(--body-text-spacer) * 1.2 );    
  }

  &--grid {
    @media (min-width: $bp--medium) {
      display: grid;
      grid-template-columns: minmax(22rem, 45rem) minmax(22rem, 1fr);
    }
    h2 + & {
      margin-top: calc( var(--body-text-spacer) * 2 );
    }
  }
  &--main {
    .content-section--aside + & {
      padding-top: calc( var(--body-text-spacer) * 1 );
    }
  }
  @media (min-width: $bp--medium) {
    &--main {
      padding-right: 2rem;
      .content-section--aside + & {
        padding-top: calc( var(--body-text-spacer) * 2 );
      }
    }
    &--aside {
      padding-left: 2rem;
      &.sticky {
        display: block;
        position: -webkit-sticky;
        position: sticky;
        top: calc( var(--body-text-spacer) * 5 );
      }
    }
  }
  & + .content-section {
    margin-top: calc( var(--body-text-spacer) * 4 );
  }
  .color + & {
    margin-top: calc( var(--body-text-spacer) * 3 );
  }
}

.axis {
  h2::before {
    display: block;
    font-family: $sans-serif-novf;
    font-size: 1rem;
    font-weight: 500;
    font-variant-caps: all-small-caps;
    letter-spacing: 0.1em;
    margin-bottom: calc( var(--body-text-spacer) * 0.5 );
    @supports (font-variation-settings: normal) {
      font-family: $sans-serif;
    }
  }
  &.registered > h2::before {
    content: "Registered Axis";
  }
  &.custom > h2::before {
    content: "Custom Axis";
  }
}

.padded-bottom {
  padding-bottom: calc( var(--body-text-spacer) * 1 );
}

.scroll-padded {
  padding-top: calc( var(--body-text-spacer) * 1 );
}