// Helper mixins
// =============================================================================

// Font stacks
$serif-fallback: Georgia, "Times New Roman", serif;
$sans-serif-fallback: Helvetica, Arial, sans-serif;

$serif-novf: "Roboto Slab Static", $serif-fallback;
$sans-serif-novf: "Roboto", $sans-serif-fallback;

$serif: "Roboto Slab", $serif-fallback;
$sans-serif: "Roboto Extremo", $sans-serif-fallback;

@mixin content-width() {
  max-width: 39rem;

  @media screen and (min-width: $bp--large) {
    max-width: 40rem;
  }

  @media screen and (min-width: $bp--xlarge) {
    max-width: 41rem;
  }
}
@mixin content-min-width() {
  width: 100%;
  max-width: 100%;

  @media screen and (min-width: $bp--large) {
    min-width: 40rem;
    width: auto;
  }

  @media screen and (min-width: $bp--xlarge) {
    min-width: 41rem;
  }
}
:root {
  --content-width: 39rem;

  @media screen and (min-width: $bp--large) {
    --content-width: 40rem;
  }

  @media screen and (min-width: $bp--xlarge) {
    --content-width: 41rem;
  }
}

@mixin section-width() {
  margin: 0 auto;
  max-width: 96vw;
  @media screen and (min-width: $bp--medium) {
    max-width: 94vw;
  }
  @media screen and (min-width: $bp--large) {
    max-width: 90vw;
  }
  @media screen and (min-width: $bp--xxxlarge) {
    max-width: 90rem;
  }
}

.visually-hidden:not(:focus):not(:active) { 
  position: absolute !important;
  height: 1px; 
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap; /* added line */
}

.vertical-center-flex {
  align-self: center;
}

/* Used to set the page backgrounds, without
 * being page-specific */
.header-background-gray-light   { 
  background-color: $header-background-gray-light; 
  background-color: var(--header-background-gray-light);
}
.header-background-gray-lighter { 
  background-color: $header-background-gray-lighter; 
  background-color: var(--header-background-gray-lighter);
}
.header-background-blue-lighter { 
  background-color: $header-background-blue-lighter; 
  background-color: var(--header-background-blue-lighter);
}

/*
 * Helper class to avoid single, trailing words 
 * in headers. At the time of writing, only applied
 * manually in the hero section.
 */
.widont {
  display: inline-block; /* Ignore the breaking space */
  @media (min-width: $bp--medium) {
    display: inline; /* Restore the breaking space at larger screen sizes */
  }
}
