.hero {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: calc( var(--body-text-spacer) * 5 );
  padding-left: 1vw;
  padding-right: 1vw;
  margin-top: 1rem;
  margin-bottom: 3rem;
  @media (min-width: $bp--medium) {
    max-width: 94vw;
    margin-top: 4rem;
  }
  @media (min-width: $bp--large) {
    padding-left: 2vw;
    padding-right: 2vw;
  }
  @media (min-width: $bp--xxlarge) {
    max-width: 2500px;
  }
}

.hero-sidebar {
  background: #EEEEEE;
  background: var(--color-hero-sidebar);
  border: 1px solid #000000;
  border-radius: 8px;
  position: relative;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  @media (min-width: $bp--medium) {
    // margin-top: 3rem;
    margin-bottom: 0;
  }
  &:before,
  &:after {
    content: "";
    width: 100%;
    // background-color: pink;
    background:
      url('../img/hero-sidebar-dot.svg') top left no-repeat,
      url('../img/hero-sidebar-dot.svg') top right no-repeat;
    height: 2rem;
    position: absolute;
    left: 0;
  }
  &:before {
    top: 0;
  }
  &:after {
    bottom: 0;
    // background-color: blue;
  }
}

.hero-sidebar-icon {
  display: none;
  visibility: hidden;
  @media (min-width: $bp--medium) {
    width: 80%;
    max-width: 200px;
    display: block;
    visibility: visible;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: -2rem;
    img {
      width: 100%;
      display: block;
    }
  }
}

.hero-sidebar-items {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
  @media (min-width: $bp--medium) {
    padding-top: 1rem;
    padding-bottom: 2.2rem;
  }
}

.hero-sidebar-item {
  display: inline-block;
  width: 50%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  @media (min-width: $bp--medium) {
    display: block;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

.hero-main {
  display: block;
  padding-left: 1rem;
  padding-right: 1rem;
}

.hero-more {
  font-weight: 350;
  font-size: 0.925rem;
  font-variation-settings: 'slnt' -10;
  margin: 0 auto -1.5rem auto;
  max-width: 90%;
}


@media (min-width: $bp--medium) {
  .hero {
    // Flex layout can break sliders (!)
    display: flex;
    align-items: stretch;
  }
  
  .hero-main {
    width: 70%;
  }
  
  .hero-sidebar {
    width: 30%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: $bp--xxlarge) {
  .hero-sidebar {
    width: 20%;
  }
  
  .hero-main {
    width: 80%
  }
}

.hero-animation {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: solid 1px var(--color-text-callout);

  // All Variable Axes color is default
  background-color: var(--color-callout-highlight, #FFF8E1);
}

.hero-button {
  width: 100%;
  padding: 1rem;
  appearance: none;
  border: solid 1px var(--color-text-callout);
  border-radius: 100px;
  box-sizing: 0 0 0 rgba(0, 0, 0, 0);
  transition: box-shadow 100ms;
  will-change: box-shadow;
  text-transform: uppercase;
  &:focus,
  &:hover {
    outline: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}

.hero-button--active {
  background-color: var(--color-callout-highlight, #FFF8E1);  
  color: var(--color-text);
}

.hero-animation-frame {
  border-top: solid 1px var(--color-text-callout);
  display: flex;
  align-items: center;
  min-height: 50vh;
  height: 100%;
}

.hero-animation {
  height: 100%; 
}

.hero-animation-text {
  display: block;
  text-align: center;

  // Optically align in the centre
  transform: translateY(-5%);
}

.hero-animation,
.hero-animation .range__axis__label p {
    transition: background-color 0.3s ease-in-out;  
}

[data-wght-active="true"],
[data-casl-active="true"],
[data-ital-active="true"],
[data-mono-active="true"],
[data-slnt-active="true"] {
  .hero-animation span {
    --text-vf-wght-min: 400;
    --text-vf-wght-max: 400;

    --text-vf-CASL-min: 0;
    --text-vf-CASL-max: 0;

    --text-vf-ital-min: 0;
    --text-vf-ital-max: 0;

    --text-vf-MONO-min: 1;
    --text-vf-MONO-max: 1;

    --text-vf-slnt-min: 0;
    --text-vf-slnt-max: 0;
    font-variation-settings: 
      "wght" var(--text-vf-wght-min),
      "CASL" var(--text-vf-CASL-min),
      "ital" var(--text-vf-ital-min),
      "MONO" var(--text-vf-MONO-min),
      "slnt" var(--text-vf-slnt-min);
  }
}

[data-wght-active="true"]  {
  --color-callout-highlight: #{$color-callout-wght};
  .hero-animation-text span {
    --text-vf-wght-min: 100;
    --text-vf-wght-max: 1000;
    animation-delay: 0s;
    animation: breath-wght 1.5s alternate ease-in-out;
    animation-iteration-count: 500;
  }
}

[data-wdth-active="true"]  {
  --color-callout-highlight: #{$color-callout-wdth};
  .hero-animation-text span {
    --text-vf-wdth-min: 75;
    --text-vf-wdth-max: 100;
    animation-delay: 0s;
    animation: breath-wdth 1.5s alternate ease-in-out;
    animation-iteration-count: 500;
  }
}
[data-ital-active="true"]  {
  --color-callout-highlight: #{$color-callout-ital};
  .hero-animation-text span {
    --text-vf-ital-min: 0;
    --text-vf-ital-max: 1;
    animation-delay: 0s;
    animation: breath-ital 1.5s alternate ease-in-out;
    animation-iteration-count: 500;
  }
}
[data-slnt-active="true"]  {
  --color-callout-highlight: #{$color-callout-slnt};
  .hero-animation-text span {
    --text-vf-slnt-min: -15;
    --text-vf-slnt-max: 0;
    animation-delay: 0s;
    animation: breath-slnt 1.5s alternate ease-in-out;
    animation-iteration-count: 500;
  }
}
[data-opsz-active="true"]  {
  --color-callout-highlight: #{$color-callout-opsz};
  .hero-animation-text span {
    --text-vf-opsz-min: 14;
    --text-vf-opsz-max: 64;
    animation-delay: 0s;
    animation: breath-opsz 1.5s alternate ease-in-out;
    animation-iteration-count: 500;
  }
}
[data-casl-active="true"]  {
  --color-callout-highlight: #{$color-callout-casl};
  .hero-animation-text span {
    --text-vf-CASL-min: 0;
    --text-vf-CASL-max: 1;
    animation-delay: 0s;
    animation: breath-casl 1.5s alternate ease-in-out;
    animation-iteration-count: 500;
  }
}
[data-mono-active="true"]  {

  .hero-animation-text span {
    --text-vf-MONO-min: 0;
    --text-vf-MONO-max: 1;
    animation-delay: 0s;
    animation: breath-mono 1.5s alternate ease-in-out;
    animation-iteration-count: 500;
  }
}


// [data-wght-active="true"] [data-axis="wght"] {
//   outline: 2px solid red;
// }



    

@keyframes breath-wght {
  0% {
    font-variation-settings: 
      "wght" var(--text-vf-wght-min),
      "CASL" var(--text-vf-CASL-min),
      "ital" var(--text-vf-ital-min),
      "MONO" var(--text-vf-MONO-min),
      "slnt" var(--text-vf-slnt-min);
  }

  100% {
    font-variation-settings:
      "wght" var(--text-vf-wght-max),
      "CASL" var(--text-vf-CASL-max),
      "ital" var(--text-vf-ital-max),
      "MONO" var(--text-vf-MONO-max),
      "slnt" var(--text-vf-slnt-max);
  }
}

@keyframes breath-casl {
  0% {
    font-variation-settings: 
      "wght" var(--text-vf-wght-min),
      "CASL" var(--text-vf-CASL-min),
      "ital" var(--text-vf-ital-min),
      "MONO" var(--text-vf-MONO-min),
      "slnt" var(--text-vf-slnt-min);
  }

  100% {
    font-variation-settings:
      "wght" var(--text-vf-wght-max),
      "CASL" var(--text-vf-CASL-max),
      "ital" var(--text-vf-ital-max),
      "MONO" var(--text-vf-MONO-max),
      "slnt" var(--text-vf-slnt-max);
  }
}
@keyframes breath-ital {
  0% {
    font-variation-settings: 
      "wght" var(--text-vf-wght-min),
      "CASL" var(--text-vf-CASL-min),
      "ital" var(--text-vf-ital-min),
      "MONO" var(--text-vf-MONO-min),
      "slnt" var(--text-vf-slnt-min);
  }

  100% {
    font-variation-settings:
      "wght" var(--text-vf-wght-max),
      "CASL" var(--text-vf-CASL-max),
      "ital" var(--text-vf-ital-max),
      "MONO" var(--text-vf-MONO-max),
      "slnt" var(--text-vf-slnt-max);
  }
}
@keyframes breath-mono {
  0% {
    font-variation-settings: 
      "wght" var(--text-vf-wght-min),
      "CASL" var(--text-vf-CASL-min),
      "ital" var(--text-vf-ital-min),
      "MONO" var(--text-vf-MONO-min),
      "slnt" var(--text-vf-slnt-min);
  }

  100% {
    font-variation-settings:
      "wght" var(--text-vf-wght-max),
      "CASL" var(--text-vf-CASL-max),
      "ital" var(--text-vf-ital-max),
      "MONO" var(--text-vf-MONO-max),
      "slnt" var(--text-vf-slnt-max);
  }
}
@keyframes breath-slnt {
  0% {
    font-variation-settings: 
      "wght" var(--text-vf-wght-min),
      "CASL" var(--text-vf-CASL-min),
      "ital" var(--text-vf-ital-min),
      "MONO" var(--text-vf-MONO-min),
      "slnt" var(--text-vf-slnt-min);
  }

  100% {
    font-variation-settings:
      "wght" var(--text-vf-wght-max),
      "CASL" var(--text-vf-CASL-max),
      "ital" var(--text-vf-ital-max),
      "MONO" var(--text-vf-MONO-max),
      "slnt" var(--text-vf-slnt-max);
  }
}
