pre.css {
  background-color: $color-gray-200;
  color: $color-black;
  border: none !important;
  border-radius: 0.5rem;
  overflow: hidden;
  padding: 0 0 1.5rem 0 !important;
  position: relative;
  p + & {
    margin-top: calc( var(--body-text-spacer) * 2 );
  }
  &::before {
    content: "CSS";
    background-color: $color-gray-300;
    color: $color-gray-800;
    display: block;
    font-family: $sans-serif-novf;
    font-size: 0.8rem;
    padding: 0.6em 1.5rem;
    line-height: 1;
    text-align: left;
    width: 100%;
    @supports (font-variation-settings: normal) {
      font-family: $sans-serif;
    }
  }
  @media (prefers-color-scheme: dark) {
    background-color: $color-gray-800;
    color: $color-gray-200;
    &::before {
      background-color: $color-black;
      color: $color-gray-300;
    }
  }
}

code.prettyprint {
  background-color: transparent !important;
  border: none !important;
  display: block;
  font-size: 0.9rem;
  line-height: 1.5;
  overflow: scroll;
  padding: 0 !important;
}

.prettyprint {
  .pln {
    color: var(--color-text);
    color: var(--color-prettify-pln);
  }
  .str {
    color: #060;
    color: var(--color-prettify-str);
  }
  .typ {
    color: #606;
    color: var(--color-prettify-typ);
  }
  .lit {
    color: #055;
    color: var(--color-prettify-lit);
  }
  .clo,
  .opn,
  .pun {
    color: #550;
    color: var(--color-prettify-pun);
  }
}
