// HTML Elements
// =============================================================================

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}
@media screen and (prefers-reduced-motion: reduce) {
	html {
		scroll-behavior: auto;
	}
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  display: flex;
  height: 100vh;
  margin: 0;
  padding: 0;
}

figure {
  margin: 0;
  padding: 0;
  width: calc( 49% + 2rem );
  &.figure--right {
    float: right;
    margin-left: 4rem;
    @media (min-width: $bp--large) {
      min-width: 15rem;
      width: calc( 100% - (var(--content-width) + 4rem) );
    }
  }
  img {
    border: solid 1px $color-gray-200;
  }

  &.large {
    float: none;
    width: 100%;
  }
  * + & {
    margin-top: var(--body-text-spacer);
  }
}

figcaption {
  font-weight: 350;
  font-size: 0.925rem;
  margin-bottom: 1rem;
  margin-top: 0.25rem;
  font-variation-settings: 'slnt' -10;
}

img {
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%;
}

/* first reset the default style, then add the section mark using the 'content' attribute */
hr {
  border: none;
}
hr::after {
  color: $color-blue-100;
  content: "§";
  display: block;
  font-family: $sans-serif-novf;
  font-size: 1.5em;
  font-weight: bold;
  line-height: 1;
  margin: 1em auto;
  padding-top: 0.1em;
  text-align: center;
  background: radial-gradient(0.801em at 50%, var(--color-background) 0.8em, transparent),
    linear-gradient(
        to left,
        $color-gray-200,
        $color-gray-200,
        $color-gray-200
      )
      center / 100% 1px no-repeat,
    linear-gradient(
        to left,
        rgba($color-gray-200, 0.5),
        transparent 25%,
        transparent 75%,
        rgba($color-gray-200, 0.5)
      )
      center / 100% 3px no-repeat;
  @supports (font-variation-settings: normal) {
    font-family: $sans-serif;
    padding-top: 0.05em;
  }
}



.aside {
  --color-text-highlight: #{$color-peach-light};
  background-color: var(--color-text-highlight);
  border-radius: 0.5rem;
  max-width: var(--content-width);
  padding: var(--body-text-spacer) 1rem;
  &--right {
    min-width: 15rem;
    @media (min-width: $bp--large) {
      clear: right;
      float: right;
      margin-left: 4rem;
      margin-top: 0;
      min-width: 15rem;
      width: calc( 100% - (var(--content-width) + 4rem) );
    }
  }
  
  h3 {
    --h3-font-size-min: 1.25;
    --h3-font-size-max: 1.5;
  }
  
  .content-section & h4 {
    margin-top: 0;
  }
  p {
    --p-font-size-min: 0.875;
    --p-font-size-max: 1;
  }
  p + & {
    margin-top: calc( var(--body-text-spacer) * 1 );
  }
}

table {
  @include content-min-width;
  background: var(--color-text-highlight-secondary);
  border-radius: 0.5rem;
  border-spacing: 1rem;
  padding: 0.5rem;
  * + & {
    margin-top: calc( var(--body-text-spacer) * 2 );
  }
}
th {
  border-bottom: solid 1px $color-gray-300;
  padding: 0.25rem;
  text-align: left;
}
td {
  padding: 0.15rem 0.25rem;
}
