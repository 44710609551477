blockquote {
  @include content-width;
  //border-left: solid 0.5em $color-blue-300;
  margin-left: 0;
  margin-top: 0;
  margin-bottom: var(--body-text-spacer);

  * + & {
    margin-top: calc( var(--body-text-spacer) * 2 );
  }

  p {
    color: $color-gray-700;
    font-family: $sans-serif;
  	font-style: italic;
    font-weight: 200;
    margin-left: 0;
    margin-bottom: 0;
    padding-left: 0.75em;
    position: relative;
    z-index: 1;
    + p {
      margin-top : var(--body-text-spacer);
    }
    &:after {
      content: '“';
      color: rgba($color-gray-500, .25);
      float: left;
      font-family: $serif-fallback;
      font-size: 5em;
      line-height: 1;
      position: absolute;
      left: -0.2em;
      top: -0.15em;
      z-index: 0;
    }
	}
  @supports (font-variation-settings: normal) {
    p {
      --p-line-height-min: 1.2;
      --p-line-height-max: 1.3;
      --p-font-size-min: 1.75;
      --p-font-size-max: 2;
      --p-vf-slnt: -10;
      --p-vf-wght: 200;
      --text-opsz: calc( var(--p-font-size-min) * var(--text-size-base) );
      color: var(--color-text-callout);
      font-style: italic;
      font-synthesis: none;
      font-weight: var(--p-vf-wght);
  
      @media (min-width: $bp--xxlarge) {
        --text-opsz: calc( var(--p-font-size-max) * var(--text-size-base) );
      }
    }
  }
  &.left {
    clear: left;
    float: left;
    @media screen and (min-width: $bp--medium) {
      margin-left: 1rem;
      margin-right: 0.5rem;
    }
  }
  &.right {
    padding-right: 0.5rem;
    @media screen and (min-width: $bp--medium) {
      clear: right;
      float: right;
      margin-left: 4rem;
      margin-right: 0;
      margin-top: 0;
      min-width: 15rem;
      padding-right: 1rem;
      width: calc( 100% - (var(--content-width) + 4rem) );
    }
  }
  img {
    height: auto;
    max-width: 100%;
    width: 100%;
  }
  cite {
    @include text-smedium;
    font-style: italic;
    padding-left: 1.5rem;
  }
}
