@import "@material/drawer/mdc-drawer";
@import "@material/list/mdc-list";

.mdc-drawer {
  // Updated to pass WCAG 2.1 AAA contrast ratio
  --mdc-theme-primary: #{$color-blue-100};
  background-color: #ffffff;
  background-color: var(--color-background);
  width: 75vw;
  .mdc-drawer &__title {
    color: var(--color-text);
  }
  &__content {
    @media (min-width: $bp--large) {
      // This was already here, matches the fixed navbar height
      // in top-app-bar, but might not be necessary?
      height: 4rem;
    }
  }
  // &--modal {
  //   @media (min-width: $bp--large) {
  //     border: none;
  //     box-shadow: none;
  //     display: block;
  //     height: auto;
  //     left: auto;
  //     right: 0;
  //     top: 0;
  //   }
  // }
  .mdc-list-item {
    font-family: $sans-serif-novf;
    @supports (font-variation-settings: normal) {
      font-family: $sans-serif;
    }
    @media (prefers-color-scheme:dark) {
      color: #ffffff;
    }
    &__text {
      text-overflow: inherit;
      white-space: normal;
      overflow: hidden;
    }
  }
  .mdc-list-item--activated {
    // Updated to pass WCAG 2.1 AAA contrast ratio
    color: $color-blue-900;
    font-weight: 600;
    @media (prefers-color-scheme:dark) {
      color: $color-blue-100;
    }
  }
}

