// Text styles
// =============================================================================

:root {
  --vf-support: false;
  @supports (font-variation-settings: normal) {
    --vf-support: true;
  }
}

:root {
  /* text-size proportional spacer */
  --body-text-spacer: 1rem;
  @media screen and (min-width: $bp--medium) {
    --body-text-spacer: 1.125rem;
  }  
  @media screen and (min-width: $bp--xxlarge) {
    --body-text-spacer: 1.25rem;
  }  

  --color-background: #ffffff;
  --color-text: #{$color-text};
  --color-text-callout: #{$color-gray-700};
  --color-text-highlight: #{$color-text-highlight};
  --color-text-highlight-secondary: #{$color-text-highlight-secondary};
  --color-callout-highlight: #{darken($color-blue-50, 2.5%)};
  --color-callout-highlight-secondary: #{lighten($color-blue-50, 2.5%)};
  --color-callout-highlight-alt: #{darken($color-blue-100, 2.5%)};
  --color-callout-highlight-alt-secondary: #{lighten($color-blue-100, 2.5%)};
  --color-card-border: #{$color-card-border};
  --color-text-menu-link: #{$color-gray-750};
  --color-text-link: #{$color-text-link};
  --color-text-link-visited: #{$color-text-link-visited};
  --color-text-link-hover: #{$color-text-link-hover};
  --color-text-link-active: #{$color-text-link-active};
  --color-text-link-focus: #{$color-text-link-focus};
  --color-text-link-active-trail: #{$color-text-link-active-trail};
  --color-prettify-lit: #{$color-blue-900};
  --color-prettify-pln: #{$color-text};
  --color-prettify-pun: #{$color-gray-900};
  --color-prettify-str: #{$color-blue-900};
  --color-prettify-typ: #{$color-blue-900};
  --header-background-gray-light: #{$header-background-gray-light};
  --header-background-gray-lighter: #{$header-background-gray-lighter};
  --header-background-blue-lighter: #{$header-background-blue-lighter};
  --color-slider-group-background: #{$color-slider-group-background};
  --color-hero-sidebar: #{$color-gray-200};

  @media (prefers-color-scheme:dark) {
    --color-background: #{$color-gray-900};
    --color-text: #ffffff;
    --color-text-callout: #{$color-gray-200};
    --color-text-highlight: #{$color-gray-800};
    --color-text-highlight-secondary: #{$color-gray-700};
    --color-callout-highlight: #{darken($color-gray-700, 2.5%)};
    --color-callout-highlight-secondary: #{lighten($color-gray-700, 2.5%)};
    --color-callout-highlight-alt: #{darken($color-gray-800, 5%)};
    --color-callout-highlight-alt-secondary: #{lighten($color-gray-800, 2.5%)};
    --color-card-border: #{$color-gray-600};
    --color-text-menu-link: #{$color-gray-200};
    --color-text-link: #{$color-blue-100};
    --color-text-link-visited: #{$color-blue-100};
    --color-text-link-hover: #{lighten($color-text-link-hover, 60%)};
    --color-text-link-active: #{$color-text-link-active};
    --color-text-link-active-trail: #ffffff;
    --color-text-link-focus: #{$color-text-link-focus};
    --color-prettify-lit: #{$color-blue-200};
    --color-prettify-pln: var(--color-text);
    --color-prettify-pun: #{$color-gray-200};
    --color-prettify-str: #{$color-blue-100};
    --color-prettify-typ: #{$color-blue-100};
    --header-background-gray-light: #{rgba($header-background-gray-light,.15)};
    --header-background-gray-lighter: #{rgba($header-background-gray-lighter,.15)};
    --header-background-blue-lighter: #{rgba($color-blue-900,.15)};
    --color-slider-group-background: #{$color-gray-800};
    --color-hero-sidebar: #{$color-gray-800};
    .aside {
      --color-text-highlight: #{$color-gray-700};
    }
    .range__axis.range__axis--width {
      --color-callout-highlight: #{darken($color-lavender-light,80%)};
    }
    .range__axis.range__axis--italic {
      --color-callout-highlight: #{darken($color-yellow-light,70%)};
    }
    .range__axis.range__axis--slant {
      --color-callout-highlight: #{darken($color-green-light,80%)};
    }
    .range__axis.range__axis--width {
      --color-callout-highlight: #{darken($color-lavender-light,80%)};
    }
    .range__axis.range__axis--italic {
      --color-callout-highlight: #{darken($color-yellow-light,70%)};
    }
    .range__axis.range__axis--slant {
      --color-callout-highlight: #{darken($color-green-light,80%)};
    }
    .range__axis.range__axis--opsz-auto {
      --color-callout-highlight: #{darken($color-gray-700, 2.5%)};
    }
    .range__axis.range__axis--opsz {
      --color-callout-highlight: #{darken($color-lavender-light,80%)};
    }
    .range__axis.range__axis--grade {
      --color-callout-highlight: #{darken($color-yellow-light,70%)};
    }
    .range__axis.range__axis--casual {
      --color-callout-highlight: #{darken($color-green-light,80%)};
    }
    .range__axis.range__axis--ascender-descender {
      --color-callout-highlight: #{darken($color-lavender-light,80%)};
    }
    [data-wght-active="true"]  {
      --color-callout-highlight: #{darken($color-callout-wght,70%)};
    }
    [data-wdth-active="true"]  {
      --color-callout-highlight: #{darken($color-callout-wdth,70%)};
    }
    [data-ital-active="true"]  {
      --color-callout-highlight: #{darken($color-callout-ital,70%)};
    }
    [data-slnt-active="true"]  {
      --color-callout-highlight: #{darken($color-callout-slnt,70%)};
    }
    [data-opsz-active="true"]  {
      --color-callout-highlight: #{darken($color-callout-opsz,70%)};
    }
    [data-casl-active="true"]  {
      --color-callout-highlight: #{darken($color-callout-casl,70%)};
    }
  }
}
.dark {
  --color-background: #{$color-gray-900};
  --color-text: #ffffff;
  --color-text-callout: #{$color-gray-200};
  --color-text-highlight: #{$color-gray-800};
  --color-text-highlight-secondary: #{$color-gray-700};
  --color-callout-highlight: #{darken($color-gray-700, 2.5%)};
  --color-callout-highlight-secondary: #{lighten($color-gray-700, 2.5%)};
  --color-callout-highlight-alt: #{darken($color-gray-800, 5%)};
  --color-callout-highlight-alt-secondary: #{lighten($color-gray-800, 2.5%)};
  --color-card-border: #{$color-gray-600};
  --color-text-menu-link: #{$color-gray-200};
  --color-text-link: #{$color-blue-100};
  --color-text-link-visited: #{$color-blue-200};
  --color-text-link-hover: #{lighten($color-text-link-hover, 60%)};
  --color-text-link-active: #{$color-text-link-active};
  --color-text-link-active-trail: #ffffff;
  --color-text-link-focus: #{$color-text-link-focus};
  --color-prettify-lit: #{$color-blue-200};
  --color-prettify-pln: var(--color-text);
  --color-prettify-pun: #{$color-gray-200};
  --color-prettify-str: #{$color-blue-100};
  --color-prettify-typ: #{$color-blue-100};
  --header-background-gray-light: #{rgba($header-background-gray-light,.15)};
  --header-background-gray-lighter: #{rgba($header-background-gray-lighter,.15)};
  --header-background-blue-lighter: #{rgba($color-blue-900,.15)};
  --color-slider-group-background: #{$color-gray-800};
  --color-hero-sidebar: #{$color-gray-800};
.aside {
    --color-text-highlight: #{$color-gray-700};
  }
  .range__axis.range__axis--width {
    --color-callout-highlight: #{darken($color-lavender-light,80%)};
  }
  .range__axis.range__axis--italic {
    --color-callout-highlight: #{darken($color-yellow-light,70%)};
  }
  .range__axis.range__axis--slant {
    --color-callout-highlight: #{darken($color-green-light,80%)};
  }
  .range__axis.range__axis--width {
    --color-callout-highlight: #{darken($color-lavender-light,80%)};
  }
  .range__axis.range__axis--italic {
    --color-callout-highlight: #{darken($color-yellow-light,70%)};
  }
  .range__axis.range__axis--slant {
    --color-callout-highlight: #{darken($color-green-light,80%)};
  }
  .range__axis.range__axis--opsz-auto {
    --color-callout-highlight: #{darken($color-gray-700, 2.5%)};
  }
  .range__axis.range__axis--opsz {
    --color-callout-highlight: #{darken($color-lavender-light,80%)};
  }
  .range__axis.range__axis--grade {
    --color-callout-highlight: #{darken($color-yellow-light,70%)};
  }
  .range__axis.range__axis--casual {
    --color-callout-highlight: #{darken($color-green-light,80%)};
  }
  .range__axis.range__axis--ascender-descender {
    --color-callout-highlight: #{darken($color-lavender-light,80%)};
  }
  [data-wght-active="true"]  {
    --color-callout-highlight: #{darken($color-callout-wght,70%)};
  }
  
  [data-wdth-active="true"]  {
    --color-callout-highlight: #{darken($color-callout-wdth,70%)};
  }
  [data-ital-active="true"]  {
    --color-callout-highlight: #{darken($color-callout-ital,70%)};
  }
  [data-slnt-active="true"]  {
    --color-callout-highlight: #{darken($color-callout-slnt,70%)};
  }
  [data-opsz-active="true"]  {
    --color-callout-highlight: #{darken($color-callout-opsz,70%)};
  }
  [data-casl-active="true"]  {
    --color-callout-highlight: #{darken($color-callout-casl,70%)};
  }
}


html {
  background-color: #ffffff;
  background-color: var(--color-background);
  color: $color-text;
  color: var(--color-text);
  font-size: 100%;
}

body {
  font-family: $sans-serif-novf;
  @supports (font-variation-settings: normal) {
    font-family: $sans-serif;
  }
}

p, 
em,
th, 
td, 
li {
	font-family: $sans-serif-novf;
	font-weight: normal;
  font-style: normal;
  .wf-inactive & {
	  font-family: $sans-serif-fallback;
  }
  @supports (font-variation-settings: normal) {
    font-family: $sans-serif;
  	font-weight: var(--p-vf-wght);
    font-variation-settings: 'opsz' var(--text-opsz), 'slnt' var(--p-vf-slnt), 'GRAD' var(--p-vf-GRAD);
  }
}


p,
li, 
th,
td {
  @include text-body;
  @include content-width;
  margin-top: 0;
  margin-bottom: 0;
  -webkit-hyphens: auto;
  hyphens: auto;

  .wf-inactive & {
	  font-size: 1.075em;
	  line-height: 1.125;
	  word-spacing: -0.75px;
  }
  @media screen and (min-width: $bp--small) {
    .wf-inactive & {
      line-height: 1.2;
    }
  }
  @media screen and (min-width: $bp--medium) {
    -webkit-hyphens: none;
    hyphens: none;
    .wf-inactive & {
      font-size: 1.1em;
      line-height: 1.375;
      word-spacing: -1px;
        }
  }
  @media (min-width: $bp--large) {
    .wf-inactive & {
  	  font-size: 1.1em;
      line-height: 1.4;
      word-spacing: 1.75px;
    }
  }
  @media (min-width: $bp--xxlarge) {
    .wf-inactive & {
  	  font-size: 1.25em;
  	  line-height: 1.3;
  	  letter-spacing: 0.25px;
  	  word-spacing: normal;
    }
  }
  @supports (font-variation-settings: normal) {
    --text-opsz: calc( var(--p-font-size-min) * var(--text-size-base) );
    font-size: calc( var(--p-font-size-min) * 1rem );
    font-weight: var(--p-vf-wght);
    line-height: var(--p-line-height-min);
    @media screen and (min-width: $bp--small) {
      line-height: calc(( var(--p-line-height-min) * 1em ) + ( var(--p-line-height-max) - var(--p-line-height-min) ) * ((100vw - ( var(--bp-small) * 1em )) / ( var(--bp-large) - var(--bp-small) )));
    }
    @media (min-width: $bp--large) {
      font-size: calc(( var(--p-font-size-min) * 1em ) + ( var(--p-font-size-max) - var(--p-font-size-min) ) * ((100vw - ( var(--bp-large) * 1em )) / ( var(--bp-xxlarge) - var(--bp-large) )));
      line-height: var(--p-line-height-max);
    }
    @media (min-width: $bp--xxlarge) {
      --text-opsz: calc( var(--p-font-size-max) * var(--text-size-base) );
      font-size: calc( var(--p-font-size-max) * 1rem );
    }    
  }
  @media (prefers-color-scheme:dark) {
    --p-vf-GRAD: -0.35;
    letter-spacing: 0.025em;
  }
  p + &, 
  ul + &,
  ol + &,
  aside + &,
  .aside + &,
  figure + &,
  pre + & {
    margin-top: var(--body-text-spacer);
  }
  h3 + &,
  h4 + &,
  h2 + &,
  h2 + figure + & {
    margin-top: calc( var(--body-text-spacer) * 1 );
  }
}

ol,
ul {
  @include content-width;
  margin: var(--body-text-spacer) 0 0 0;
  ul &,
  ol & {
    margin-top: calc( var(--body-text-spacer) * 0.25 );
  }
}

.lede {
  --p-font-size-min: 1.33;
  --p-font-size-max: 1.5;
  --p-vf-wght: 200;
  &:first-of-type::first-line {
    font-weight: var(--link-font-weight, 525);
  }
}
strong {
  @supports (font-variation-settings: normal) {
    font-synthesis: none;
    font-weight: calc(var(--p-vf-wght) * 1.85);
  }
}

.medium {
  font-weight: bold;
  @supports (font-variation-settings: normal) {
    font-synthesis: none;
    font-weight: calc(var(--p-vf-wght) * 1.75);
  }
}

th {
  font-weight: normal;
  @supports (font-variation-settings: normal) {
    font-synthesis: none;
    font-weight: var(--p-vf-wght);
  }
}

em {
  @supports (font-variation-settings: normal) {
    font-family: $sans-serif;
    font-synthesis: none;
    --p-vf-slnt: -10;
  }
}


h1 {
	@include text-xlarge;
  @include section-width();
	font-family: $sans-serif-novf;
  font-weight: 300;
  margin-bottom: calc( var(--body-text-spacer) * 2 );
  padding-top: calc( var(--body-text-spacer) * 3 );
  .wf-inactive & {
	  font-family: $sans-serif-fallback;
	  letter-spacing: -.5px;
  }
  @media screen and (min-width: $bp--medium) {
    .wf-inactive & {
  	  letter-spacing: normal;
    }
  }
  @media screen and (min-width: $bp--large) {
    .wf-inactive & {
      font-size: 3rem;
    }
  }
  @media (min-width: $bp--xxlarge) {
    .wf-inactive & {
      font-size: 4.05em;
    }
  }
  @supports (font-variation-settings: normal) {
    font-family: $sans-serif;
    font-weight: calc( var(--p-vf-wght) * var(--h1-vf-wght-multiplier-s) );
    font-size: calc( var(--h1-font-size-min) * 1em );
    font-stretch: calc( var(--h1-vf-wdth) * 1% );
    font-style: normal;
    line-height: var(--h1-line-height-min);
    .wf-inactive & {
      
    }
    @media screen and (min-width: $bp--small) {
      line-height: calc(( var(--h1-line-height-min) * 1em ) + ( var(--h1-line-height-max) - var(--h1-line-height-min) ) * ((100vw - ( var(--bp-small) * 1em )) / ( var(--bp-xxlarge) - var(--bp-small) )));
      font-size: calc(( var(--h1-font-size-min) * 1em ) + ( var(--h1-font-size-max) - var(--h1-font-size-min) ) * ((100vw - ( var(--bp-small) * 1em )) / ( var(--bp-xxlarge) - var(--bp-small) )));
      .wf-inactive & {
        font-size: calc(( var(--h1-font-size-min) * 1em ) + ( var(--h1-font-size-max) - var(--h1-font-size-min) ) * ((100vw - ( var(--bp-small) * 1em )) / ( var(--bp-xxlarge) - var(--bp-small) )));

      }
    }
    @media screen and (min-width: $bp--medium) {
      font-weight: calc( var(--p-vf-wght) * var(--h1-vf-wght-multiplier-m) );
      .wf-inactive & {
        
      }
    }
    @media (min-width: $bp--xlarge) {
      font-weight: calc( var(--p-vf-wght) * var(--h1-vf-wght-multiplier-l) );
      .wf-inactive & {
        
      }
    }
    @media (min-width: $bp--xxlarge) {
      font-size: calc( var(--h1-font-size-max) * 1em );
      line-height: var(--h1-line-height-max);
    }
  
  }
}

h2 {
	@include text-large;
	font-family: $sans-serif-novf;
	font-weight: 300;
  margin-top: 1.5rem;
  margin-bottom: 0;
  max-width: var(--content-width);
  .wf-inactive & {
	  font-family: $sans-serif-fallback;
	  letter-spacing: -.75px;
  }
  @media screen and (min-width: $bp--medium) {
    .wf-inactive & {
  	  letter-spacing: 0.5px;
  	  word-spacing: -1px;
    }
  }
  @media (min-width: $bp--xlarge) {
    .wf-inactive & {
  	  letter-spacing: 0.5px;
    }
  }
  * + & {
    margin-top: calc( var(--body-text-spacer) * 2 );
  }
  + blockquote.right {
    &,
    & + p {
      margin-top: calc( var(--body-text-spacer) * 2 );
    }
  }

  @supports (font-variation-settings: normal) {
  	font-family: $sans-serif;
    font-size: calc( var(--h2-font-size-min) * 1em );
    font-stretch: calc( var(--h2-vf-wdth) * 1% );
  	font-weight: calc( var(--p-vf-wght) * var(--h2-vf-wght-multiplier-s) );
    line-height: var(--h2-line-height-min);
    .wf-inactive & {
  	  /* loading styles here */
    }
    @media screen and (min-width: $bp--small) {
      line-height: calc(( var(--h2-line-height-min) * 1em ) + ( var(--h2-line-height-max) - var(--h2-line-height-min) ) * ((100vw - ( var(--bp-small) * 1em )) / ( var(--bp-xxlarge) - var(--bp-small) )));
      font-size: calc(( var(--h2-font-size-min) * 1em ) + ( var(--h2-font-size-max) - var(--h2-font-size-min) ) * ((100vw - ( var(--bp-small) * 1em )) / ( var(--bp-xxlarge) - var(--bp-small) )));
      .wf-inactive & {
    	  font-size: calc(( var(--h2-font-size-min) * 1em ) + ( var(--h2-font-size-max) - var(--h2-font-size-min) ) * ((100vw - ( var(--bp-small) * 1em )) / ( var(--bp-xxlarge) - var(--bp-small) )));
      }
    }
    @media screen and (min-width: $bp--medium) {
      font-weight: calc( var(--p-vf-wght) * var(--h2-vf-wght-multiplier-m) );
    }
    @media screen and (min-width: $bp--large) {

    }
    @media (min-width: $bp--xlarge) {
      font-weight: calc( var(--p-vf-wght) * var(--h2-vf-wght-multiplier-l) );
    }
    @media (min-width: $bp--xxlarge) {
      font-size: calc( var(--h2-font-size-max) * 1em );
      line-height: var(--h2-line-height-max);
    }
  }
}

h3 {
	@include text-mlarge;
  @include content-width;
	font-family: $sans-serif-novf;
	font-weight: 400;
  margin-top: 0;
  margin-bottom: calc( var(--body-text-spacer) * 1 );
  .wf-inactive & {
	  font-family: $sans-serif-fallback;
	  letter-spacing: -.75px;
  }
  @media screen and (min-width: $bp--medium) {
    .wf-inactive & {
  	  letter-spacing: 0.5px;
  	  word-spacing: -1px;
    }
  }
  @media screen and (min-width: $bp--large) {
    .wf-inactive & {
  	  letter-spacing: 0.5px;
  	  word-spacing: -1px;
    }
  }
  @media (min-width: $bp--xlarge) {
    .wf-inactive & {
  	  letter-spacing: 0.5px;
  	  word-spacing: -1px;
    }
  }
  * + & {
    margin-top: calc( var(--body-text-spacer) * 2 );
  }
  @supports (font-variation-settings: normal) {
    font-family: $sans-serif;
    font-size: calc( var(--h3-font-size-min) * 1em );
    font-stretch: calc( var(--h3-vf-wdth) * 1% );
  	font-weight: calc( var(--p-vf-wght) * var(--h3-vf-wght-multiplier-s) );
    line-height: var(--h3-line-height-min);
    .wf-inactive & {
  	  
    }

    @media screen and (min-width: $bp--small) {
      line-height: calc(( var(--h3-line-height-min) * 1em ) + ( var(--h3-line-height-max) - var(--h3-line-height-min) ) * ((100vw - ( var(--bp-small) * 1em )) / ( var(--bp-xxlarge) - var(--bp-small) )));
      font-size: calc(( var(--h3-font-size-min) * 1em ) + ( var(--h3-font-size-max) - var(--h3-font-size-min) ) * ((100vw - ( var(--bp-small) * 1em )) / ( var(--bp-xxlarge) - var(--bp-small) )));
      .wf-inactive & {
    	  font-size: calc(( var(--h3-font-size-min) * 1em ) + ( var(--h3-font-size-max) - var(--h3-font-size-min) ) * ((100vw - ( var(--bp-small) * 1em )) / ( var(--bp-xxlarge) - var(--bp-small) )));
      }
    }
    @media screen and (min-width: $bp--medium) {
      font-weight: calc( var(--p-vf-wght) * var(--h3-vf-wght-multiplier-m) );
    }
    @media screen and (min-width: $bp--large) {

    }
    @media (min-width: $bp--xlarge) {
      font-weight: calc( var(--p-vf-wght) * var(--h3-vf-wght-multiplier-l) );
    }
    @media (min-width: $bp--xxlarge) {
      font-size: calc( var(--h3-font-size-max) * 1em );
      line-height: var(--h3-line-height-max);
    }
  }
}

h4 {
	@include text-medium;
  @include content-width;
	font-family: $sans-serif-novf;
	font-weight: bold;
  margin-top: 0;
  margin-bottom: calc( var(--body-text-spacer) / 4 );
  .wf-inactive & {
	  font-family: $sans-serif-fallback;
	  letter-spacing: -.75px;
  }
  @media screen and (min-width: $bp--medium) {
    margin-bottom: calc( var(--body-text-spacer) / 2 );
    .wf-inactive & {
  	  letter-spacing: -1px;
    }
  }
  @media (min-width: $bp--xlarge) {
    .wf-inactive & {
  	  letter-spacing: -1px;
  	  word-spacing: -1px;
    }
  }
  * + & {
    margin-top: calc( var(--body-text-spacer) * 1.5 );
  }
  @supports (font-variation-settings: normal) {
  	font-family: $sans-serif;
    font-size: calc( var(--h4-font-size-min) * 1em );
    font-stretch: 80%;
  	font-weight: calc( var(--p-vf-wght) * var(--h4-vf-wght-multiplier-s) );
    line-height: var(--h4-line-height-min);
    .wf-inactive & {
  	  
    }
    @media screen and (min-width: $bp--small) {
      line-height: calc(( var(--h4-line-height-min) * 1em ) + ( var(--h4-line-height-max) - var(--h4-line-height-min) ) * ((100vw - ( var(--bp-small) * 1em )) / ( var(--bp-xxlarge) - var(--bp-small) )));
      font-size: calc(( var(--h4-font-size-min) * 1em ) + ( var(--h4-font-size-max) - var(--h4-font-size-min) ) * ((100vw - ( var(--bp-small) * 1em )) / ( var(--bp-xxlarge) - var(--bp-small) )));
      .wf-inactive & {
    	  font-size: calc(( var(--h4-font-size-min) * 1em ) + ( var(--h4-font-size-max) - var(--h4-font-size-min) ) * ((100vw - ( var(--bp-small) * 1em )) / ( var(--bp-xxlarge) - var(--bp-small) )));
      }
    }
    @media screen and (min-width: $bp--medium) {
      font-weight: calc( var(--p-vf-wght) * var(--h4-vf-wght-multiplier-m) );
    }
    @media screen and (min-width: $bp--large) {

    }
    @media (min-width: $bp--xlarge) {
      font-weight: calc( var(--p-vf-wght) * var(--h4-vf-wght-multiplier-l) );
    }
    @media (min-width: $bp--xxlarge) {
      font-size: calc( var(--h4-font-size-max) * 1em );
      line-height: var(--h4-line-height-max);
    }
  }
}

h5 {
	@include text-medium;
  @include content-width;
	font-family: $sans-serif-novf;
	font-weight: bold;
  margin-top: 0;
  margin-bottom: calc( var(--body-text-spacer) / 4 );
  .wf-inactive & {
	  font-family: $serif-fallback;
	  letter-spacing: -.75px;
  }
  @media screen and (min-width: $bp--medium) {
    margin-bottom: calc( var(--body-text-spacer) / 2 );
    .wf-inactive & {
  	  letter-spacing: -1px;
    }
  }
  @media (min-width: $bp--xlarge) {
    .wf-inactive & {
  	  letter-spacing: -1px;
  	  word-spacing: -1px;
    }
  }
  p + & {
    margin-top: 1rem;
  }
  @supports (font-variation-settings: normal) {
  	font-family: $sans-serif;
    font-size: calc( var(--h5-font-size-min) * 1em );
    font-stretch: 90%;
  	font-weight: calc( var(--p-vf-wght) * var(--h5-vf-wght-multiplier-s) );
    line-height: var(--h5-line-height-min);
    .wf-inactive & {
  	  
    }
    @media screen and (min-width: $bp--small) {
      line-height: calc(( var(--h5-line-height-min) * 1em ) + ( var(--h5-line-height-max) - var(--h5-line-height-min) ) * ((100vw - ( var(--bp-small) * 1em )) / ( var(--bp-xxlarge) - var(--bp-small) )));
      font-size: calc(( var(--h5-font-size-min) * 1em ) + ( var(--h5-font-size-max) - var(--h5-font-size-min) ) * ((100vw - ( var(--bp-small) * 1em )) / ( var(--bp-xxlarge) - var(--bp-small) )));
      .wf-inactive & {
    	  font-size: calc(( var(--h5-font-size-min) * 1em ) + ( var(--h5-font-size-max) - var(--h5-font-size-min) ) * ((100vw - ( var(--bp-small) * 1em )) / ( var(--bp-xxlarge) - var(--bp-small) )));
      }
    }
    @media screen and (min-width: $bp--medium) {
      font-weight: calc( var(--p-vf-wght) * var(--h5-vf-wght-multiplier-m) );
    }
    @media screen and (min-width: $bp--large) {

    }
    @media (min-width: $bp--xlarge) {
      font-weight: calc( var(--p-vf-wght) * var(--h5-vf-wght-multiplier-l) );
    }
    @media (min-width: $bp--xxlarge) {
      font-size: calc( var(--h5-font-size-max) * 1em );
      line-height: var(--h5-line-height-max);
    }
  }
}

code {
  --p-font-size-min: 0.925;
  --p-font-size-max: 1;
  --p-vf-wght: 450;
  @include text-body;
  background-color: var(--color-text-highlight);
  display: inline;
  font-family: monospace;
  padding: 0.15em;
  @supports (font-variation-settings: normal) {
    font-family: "Recursive", monospace;
    font-variation-settings: "MONO" 1;
    font-weight: 500;
    font-size: 0.875em;
  }
}

.manicule {
  font-family: $sans-serif;
  font-variation-settings: 'slnt' -12;
  &::before {
    content:"\0261E";
    color: $color-blue-700;
    display: inline-block;
    margin-left: -0.25rem;
    padding-right: 0.25em;
    @media screen and (min-width: $bp--small) {
      margin-left: -0.75rem;
    }
   }
}
