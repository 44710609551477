.embed-container {
  width: 100%;
  + .embed-container {
    margin-top: 2rem;
  }
  // There is a custom shadow for these cards vs. others
  .mdc-card {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    // Some axes ex. width can expand beyond the
    // card on small screens
    overflow: hidden;
  }
}
